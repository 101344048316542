import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import { colorsVar } from '@components/constants/colorVariables';
import styled from 'styled-components';
import { IGroups } from 'interfaces/groups.interfaces';
import { ReactComponent as BinRedIcon } from '@assets/icons/bin_000.svg';
import { ReactComponent as ArrowLeftIcon } from '@assets/icons/left_arrow.svg';
import { ReactComponent as EditIcon } from '@assets/icons/edit_pencil.svg';
import Notification from '@ui/notification/Notification';
import ButtonMain from '@ui/buttonMain/ButtonMain';
import PopupModal from '@ui/modal/PopupModal';
import ListParticipants from './ListParticipants.Group';
import { LineText } from '@components/commons/commonsStyled';
import ModalNameGroup from './ModalName.Group';
import { debounce } from 'lodash';
import { useDeleteGroupMutation, useGetGroupsListQuery, useUpdateGroupNameMutation } from '@store/groups/groups.api';
import notify from '@utils/notify';
import { toast } from 'react-toastify';
import { TEXT_DELETED_GROUP, TEXT_DELETE_DESCRIPTION_GROUP, TEXT_VALIDATION_NAME_GROUP } from '../constants';
import { RenderWithCondition } from '@components/hoc/RenderWithCondition';

interface IItemGroup {
  groupData: IGroups;
  isLoading?: boolean;
  onBack: () => void;
  openEditPageLeft: () => void;
  openEditPageRight: () => void;
  create?: boolean;
  buttonText?: string;
  setCreateNameGroup?: (arg: string) => void;
  valueCreateNameGroup?: string;
  handleData: (key: string, value: any) => void;
  onSave?: () => void;
  groupsList: IGroups[];
}

const ItemGroup = ({
  isLoading,
  groupData,
  onBack,
  openEditPageLeft,
  openEditPageRight,
  create,
  buttonText,
  setCreateNameGroup,
  valueCreateNameGroup,
  handleData,
  onSave,
  groupsList,
}: IItemGroup) => {
  const [openNotification, setOpenNotification] = useState<boolean | null>(false);
  const [openModalNameGroup, setOpenModalNameGroup] = useState<boolean | null>(false);
  const [changedNameGroup, setChangeNameGroup] = useState('');
  const [searchParticipants, setSearchParticipants] = useState('');
  const [searchAccessRights, setSearchAccessRights] = useState('');
  const [groupsQueryParams, setGroupsQueryParams] = useState<string | null>(null);

  const { data: groupsListWithEqualName, isFetching: isLoadingGroups } = useGetGroupsListQuery(groupsQueryParams, {
    skip: !groupsQueryParams,
  });

  const [updateNameGroup, updateGroupNameResult] = useUpdateGroupNameMutation();
  const [deleteGroup, deleteGroupResult] = useDeleteGroupMutation();

  const debounceValueParticipants = debounce((value) => {
    setSearchParticipants(value);
  }, 1000);

  const debounceValueAccessRights = debounce((value) => {
    setSearchAccessRights(value);
  }, 1000);

  const isTypeAll = useMemo(() => {
    return groupData?.type === 'ALL_IN_SPACE';
  }, [groupData]);

  useEffect(() => {
    setChangeNameGroup(groupData?.name);
  }, [groupData?.name, openModalNameGroup]);

  useEffect(() => {
    if (deleteGroupResult.isSuccess) {
      toast.success(TEXT_DELETED_GROUP, {
        draggable: true,
        position: 'top-center',
      });
    }
  }, [deleteGroupResult]);

  function handleSearchParticipants(value: string) {
    debounceValueParticipants(value);
  }
  function handleSearchAccessRights(value: string) {
    debounceValueAccessRights(value);
  }

  const participantsList = useMemo(() => {
    if (!searchParticipants) return [...groupData?.participants];
    return groupData?.participants?.filter((item) => JSON.stringify(item).includes(searchParticipants)) || [];
  }, [groupData, searchParticipants]);

  const accessRightsList = useMemo(() => {
    if (!searchAccessRights) return [...groupData?.participantsWithAccess];
    return groupData?.participantsWithAccess?.filter((item) => JSON.stringify(item).includes(searchAccessRights)) || [];
  }, [groupData, searchAccessRights]);

  useEffect(() => {
    if (groupsListWithEqualName) {
      const isExistCurrentName = groupsListWithEqualName.some((item) => item.name === changedNameGroup.trim());
      if (isExistCurrentName) {
        notify.error(TEXT_VALIDATION_NAME_GROUP, 'notification');
      } else {
        create && setCreateNameGroup && valueCreateNameGroup
          ? setCreateNameGroup(valueCreateNameGroup.trim())
          : updateNameGroup({ id: groupData?.id, params: changedNameGroup.trim() });
        setCreateNameGroup?.(changedNameGroup.trim());
        setOpenModalNameGroup(false);
        setGroupsQueryParams(null);
      }
    }
  }, [groupsListWithEqualName]);

  function handleChangeNameGroup(value: string) {
    if (create && setCreateNameGroup) {
      setCreateNameGroup(value);
    } else {
      setChangeNameGroup(value);
    }
  }
  function handleEventButton() {
    if (create) {
      onSave?.();
      onBack();
    } else {
      setOpenNotification(true);
    }
  }

  function handleDeleteGroup() {
    deleteGroup(groupData?.id);
    setOpenNotification(false);
    onBack();
  }
  function changeNameGroup() {
    setChangeNameGroup(changedNameGroup.trim());
    const paramsString = new URLSearchParams({
      query: changedNameGroup.trim(),
    });
    setGroupsQueryParams(paramsString.toString());
  }

  return (
    <ContentWrap>
      <HeaderWrap>
        <LeftBlock>
          <IconWrap onClick={onBack}>
            <ArrowLeftIcon />
          </IconWrap>
          <LineText fontSize="16px" color={colorsVar.textColor} bold={'700'} lineHeight="24px">
            {changedNameGroup}
          </LineText>
        </LeftBlock>
        <RenderWithCondition condition={!isTypeAll}>
          <div className="w-[300px]">
            <ButtonMain
              clickEvent={() => setOpenModalNameGroup(true)}
              buttonText="editName"
              type="edit"
              svgIconLeft={<EditIcon />}
            />
          </div>
        </RenderWithCondition>
      </HeaderWrap>

      <div className="flex  gap-4">
        <ListParticipants
          titleText="participants"
          dataList={participantsList}
          openEditPage={openEditPageLeft}
          handleSearchUsers={handleSearchParticipants}
          searchValue={searchParticipants}
          isTypeAll={isTypeAll}
        />
        <ListParticipants
          titleText="accessRights"
          dataList={accessRightsList}
          openEditPage={openEditPageRight}
          handleSearchUsers={handleSearchAccessRights}
          searchValue={searchAccessRights}
        />
      </div>
      <RenderWithCondition condition={!isTypeAll}>
        <ButtonWrap>
          <div className="w-[200px]">
            <ButtonMain
              clickEvent={handleEventButton}
              buttonText={buttonText ?? ''}
              type={create ? 'button' : 'delete'}
              svgIconLeft={create ? null : <BinRedIcon fill={colorsVar.redDelete} />}
            />
          </div>
        </ButtonWrap>
      </RenderWithCondition>

      <PopupModal isShown={openNotification} closeEvent={(v) => setOpenNotification(v)}>
        <Notification
          textTitle={'deleteGroupClarification'}
          textName={TEXT_DELETE_DESCRIPTION_GROUP}
          onDone={handleDeleteGroup}
          onCancel={() => setOpenNotification(false)}
        />
      </PopupModal>

      <PopupModal isShown={openModalNameGroup} closeEvent={(v) => setOpenModalNameGroup(v)}>
        <ModalNameGroup
          textTitle={'editNameGroup'}
          onDone={changeNameGroup}
          onCancel={() => {
            setChangeNameGroup('');
            setOpenModalNameGroup(false);
          }}
          textButton={'save'}
          handleChange={handleChangeNameGroup}
          inputValue={create ? valueCreateNameGroup ?? '' : changedNameGroup}
        />
      </PopupModal>
    </ContentWrap>
  );
};

export default ItemGroup;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-items: center;
  background-color: ${colorsVar.grey3};
  position: absolute;
  height: 100%;
`;

const ButtonWrap = styled.div`
  display: flex;
  padding: 4px 0px 30px;
  justify-content: end;
  height: 43px;
`;

const HeaderWrap = styled.div`
  display: flex;
  width: 100%;
  height: 64px;
  padding: 12px 24px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colorsVar.grey1};
  background: ${colorsVar.white};
`;

const LeftBlock = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;
const IconWrap = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.5s;
  border-radius: 7px;
  &:hover {
    background-color: ${colorsVar.grey1};
    transition: background-color 0.5s;
  }
`;
