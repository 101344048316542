import React, { Dispatch, useEffect, useMemo, useRef, useState } from 'react';
import { colorsVar } from '@components/constants/colorVariables';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { IGroups } from 'interfaces/groups.interfaces';
import { toast } from 'react-toastify';
import notify from '@utils/notify';
import ListGroups from './ListGroups';
import ItemGroup from './ItemGroup';
import ItemGroupEdit from './ItemGroupEdit';
import PopupModal from '@ui/modal/PopupModal';
import ModalNameGroup from './ModalName.Group';
import useDataGroup from '@hooks/useDataGroup';
import { useCreateGroupMutation, useGetGroupsListQuery } from '@store/groups/groups.api';
import { TEXT_CREATED_GROUP, TEXT_FAILED_CREATED_GROUP, TEXT_VALIDATION_NAME_GROUP } from '../constants';
import './styles.css';

interface ITableGroups {
  handleSearch: (value: string) => void;
  searchValue?: string;
  isLoading: boolean;
  groupsList: IGroups[];
  handleGetUsers: () => void;
  nextPage: number;
}

const TableGroups = ({ handleSearch, searchValue, isLoading, groupsList, handleGetUsers, nextPage }: ITableGroups) => {
  const [openListGroups, setOpenListGroups] = useState(false);
  const [openItemGroup, setOpenItemGroup] = useState(false);
  const [openEditParticipants, setOpenEditParticipants] = useState(false);
  const [openIEditAccess, setOpenEditAccess] = useState(false);
  const [currentGroup, setCurrentGroup] = useState<IGroups | null>(null);
  const [openModalNameGroup, setOpenModalNameGroup] = useState<boolean | null>(false);
  const [groupsQueryParams, setGroupsQueryParams] = useState<string | null>(null);

  const { data, createAction, newNameGroup, setNewNameGroup, handleData, paramsCreateGroup } = useDataGroup({
    groupData: currentGroup,
    groupsList,
  });
  const { data: groupsListWithEqualName, isFetching: isLoadingGroups } = useGetGroupsListQuery(groupsQueryParams, {
    skip: !groupsQueryParams,
  });

  const [createGroup, createGroupResult] = useCreateGroupMutation();

  useEffect(() => {
    setOpenListGroups(true);
  }, []);

  useEffect(() => {
    if (groupsListWithEqualName) {
      const isExistCurrentName = groupsListWithEqualName?.length;
      if (isExistCurrentName) {
        notify.error(TEXT_VALIDATION_NAME_GROUP, 'notification');
      } else {
        setOpenItemGroup(true);
        setOpenModalNameGroup(false);
        setNewNameGroup(newNameGroup.trim());
        setGroupsQueryParams(null);
      }
    }
  }, [groupsListWithEqualName]);

  useEffect(() => {
    if (createGroupResult.isSuccess) {
      toast.success(TEXT_CREATED_GROUP, {
        draggable: true,
        position: 'top-center',
      });
    }
    if (createGroupResult.error) {
      notify.error(TEXT_FAILED_CREATED_GROUP);
    }
  }, [createGroupResult]);

  function onSaveNewGroup() {
    createGroup(paramsCreateGroup);
  }

  function handleOpenGroup(data: any) {
    setCurrentGroup(data);
    setNewNameGroup(data?.name);
    setOpenItemGroup(true);
    setOpenListGroups(false);
    handleSearch('');
  }

  function handleCreateGroup() {
    setOpenModalNameGroup(true);
    setCurrentGroup(null);
    setNewNameGroup('');
  }
  function handleCloseParticipantsEdit() {
    setOpenEditParticipants(false);
    setOpenItemGroup(true);
  }
  function handleCloseAccessEdit() {
    setOpenEditAccess(false);
    setOpenItemGroup(true);
  }
  function handleSaveNewName() {
    setNewNameGroup(newNameGroup.trim());
    const paramsString = new URLSearchParams({
      query: newNameGroup.trim(),
    });
    setGroupsQueryParams(paramsString.toString());
  }

  return (
    <Wrapper>
      <CSSTransition in={openListGroups} timeout={300} classNames="ListGroups" unmountOnExit>
        <ListGroups
          handleSearch={handleSearch}
          isLoading={isLoading}
          searchValue={searchValue}
          groupsList={groupsList}
          clickEvent={handleOpenGroup}
          buttonEvent={handleCreateGroup}
          handleGetUsers={handleGetUsers}
          nextPage={nextPage}
        />
      </CSSTransition>

      <CSSTransition in={openItemGroup} timeout={500} classNames="itemGroup" unmountOnExit>
        <ItemGroup
          groupData={data}
          create={createAction}
          onBack={() => {
            setOpenItemGroup(false);
            setOpenListGroups(true);
            createAction && setNewNameGroup('');
          }}
          openEditPageLeft={() => {
            setOpenEditParticipants(true);
            setOpenItemGroup(false);
          }}
          openEditPageRight={() => {
            setOpenEditAccess(true);
            setOpenItemGroup(false);
          }}
          buttonText={createAction ? 'save' : 'deleteGroup'}
          setCreateNameGroup={setNewNameGroup}
          valueCreateNameGroup={newNameGroup}
          handleData={handleData}
          onSave={onSaveNewGroup}
          groupsList={groupsList}
        />
      </CSSTransition>

      <CSSTransition in={openEditParticipants} timeout={500} classNames="itemGroup" unmountOnExit>
        <ItemGroupEdit
          edit="participants"
          groupData={data}
          onBack={handleCloseParticipantsEdit}
          handleData={handleData}
          create={createAction}
        />
      </CSSTransition>

      <CSSTransition in={openIEditAccess} timeout={500} classNames="itemGroup" unmountOnExit>
        <ItemGroupEdit
          edit="access"
          groupData={data}
          onBack={handleCloseAccessEdit}
          handleData={handleData}
          create={createAction}
        />
      </CSSTransition>

      <PopupModal isShown={openModalNameGroup} closeEvent={(v) => setOpenModalNameGroup(v)}>
        <ModalNameGroup
          textTitle={'nameGroup'}
          onDone={handleSaveNewName}
          onCancel={() => {
            setOpenModalNameGroup(false);
            setNewNameGroup('');
          }}
          textButton={'continue'}
          handleChange={setNewNameGroup}
          inputValue={newNameGroup}
        />
      </PopupModal>
    </Wrapper>
  );
};

export default TableGroups;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1;
  height: 100%;
  flex-grow: 1;
  align-items: center;
  position: relative;
  background-color: ${colorsVar.grey3};
  border-top: 1px solid ${colorsVar.grey1};
  padding-bottom: 30px;

  @media screen and (max-width: 800px) {
    width: calc(100vw - 50px);
    min-width: inherit;
  }
  @media screen and (max-width: 700px) {
    width: 100vw;
  }
`;
